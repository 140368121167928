<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5 rm-dialog-title">Синхронизация</v-card-title>
      <v-card-text class="black--text">
        <p>
          Воронки контактов могут состоять, как из фиксированного списка
          пользователей (списка id), так и из SQL запроса в БД Таланта. При
          создании новой воронки, синхронизация запускается автоматически и CRM
          получает данные о контактах, на момент, когда воронка была создана. Но
          если эти данные поменялись (например, в базе появился контакт,
          которого не было на момент синхронизации), то эти данные автоматически
          не будут добавлены к воронке. Чтобы это случилось, нужно запустить
          синхронизацию
        </p>

        <p><strong>Статусы синхронизации</strong></p>
        <ul>
          <li>
            <strong>Синхронизация в очереди</strong> - Этот статус означает, что
            задача по синхронизации поставлена в очередь, но еще не начала
            выполняться
          </li>
          <li>
            <strong>Идет синхронизация</strong> - Этот статус означает, что в
            данный момент выполняется задача по синхронизации. Т.е. на доске
            могут находиться не все актуальные данные.
          </li>
          <li>
            <strong>Синхронизировано :относительное время:</strong> - Этот
            статус показывает, что синхронизация завершена успешно, а так же
            сообщает как давно это случилось
          </li>
          <li>
            <strong>Синхронизация #id завершилась ошибкой</strong> - Этот статус
            означает, что произошла какая-то ошибка на сервере, во время
            выполнения синхронизации. А это значит, что на доске могут
            находиться не все данные... В таком случае, мы рекомендуем запустить
            синхронизацию повторно. Если она так же завершиться ошибкой - то
            сообщить об этом в техническую поддержку сервиса.
          </li>
          <li>
            <strong>Необходима синхронизация</strong> - Этот статус означает,
            что для данной доски, никогда не запускалась синхронизация. В этом
            случае, нужно просто ее запустить.
          </li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="handleClose">Понятно</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SyncInfoDialog",
  props: {
    value: {},
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
