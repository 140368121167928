<template>
  <div class="op-list">
    <div v-for="item in userList" :key="item.id" class="op-list__item">
      <v-avatar
        :color="item.color"
        darken
        size="32"
        elevation="1"
        :title="`Работает с доской: ${item.fullName}`"
      >
        <img
          v-if="item.avatar"
          class="object-fit-cover"
          :src="item.avatar"
          :alt="item.fullName"
        />
        <span v-else class="font-weight-bold text-caption">{{
          item.initials
        }}</span>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import { getFullName } from "@/utils";
import { pathJoin } from "@/api/utils";
import sample from "lodash/sample";
const AVATAR_COLORS = [
  "deep-orange darken-2",
  "blue-grey darken-2",
  "green accent-3",
  "lime darken-4",
  "cyan accent-4",
  "light-blue darken-3",
  "light-blue darken-4",
];
export default {
  name: "OperatorsList",
  props: {
    ids: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    // Список операторов, без текущего юзера
    user() {
      return this.$store.getters["user/user"];
    },
    restOperators() {
      const { user, ids } = this;
      const idx = ids.indexOf(user.id);
      const list = [...ids];
      list.splice(idx, 1);
      return list;
    },
    userList() {
      const { restOperators, user } = this;
      const result = [
        {
          id: user.id,
          fullName: getFullName(user),
          loaded: true,
          initials: `${user.first_name[0]}.${user.last_name[0]}`,
          avatar:
            user?.avatar &&
            pathJoin(process.env.VUE_APP_AVATAR_S3, user?.avatar),
          color: "grey darken-1",
        },
      ];
      restOperators.reduce((acc, id) => {
        const u = this.$store.state.talent.users[id];
        acc.push({
          avatar: u?.avatar,
          fullName: getFullName(u) || "Неизвестный пользователь",
          loaded: !!u,
          id,
          initials: u ? `${u.first_name[0]}.${u.last_name[0]}` : "?.?",
          color: sample(AVATAR_COLORS),
        });
        return acc;
      }, result);
      // вернем не более 6
      return result.slice(0, 6);
    },
  },
  watch: {
    ids: {
      handler() {
        this.getUsersInfo();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    getUsersInfo() {
      const notLoaded = this.userList
        ?.filter((n) => !n.loaded)
        .map((n) => n.id);
      this.$store.dispatch("talent/getUsers", notLoaded);
    },
  },
};
</script>

<style lang="scss" scoped>
$max-operators: 6;
$max-operators-z-index: 10;
$operators-shift: -3px;

.op-list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 10px;
  position: relative;
  z-index: 0;

  &__item {
    flex-grow: 0;
    flex-shrink: 0;
    z-index: $max-operators-z-index;
    position: relative;

    &:hover {
      z-index: $max-operators-z-index + 1 !important;
    }

    @for $i from 1 through $max-operators {
      &:nth-child(#{$i}) {
        margin-left: $operators-shift * $i;
        z-index: $max-operators-z-index - $i;
      }
    }
  }
}
</style>
