<template>
  <div class="column-header" :class="{ 'is-hidden': isHidden }">
    <div
      v-ripple
      class="column-status-bar"
      :class="{
        'primary darken-1': stage.initial,
        'blue-grey darken-2': !stage.initial,
      }"
    >
      <div v-if="stage.initial" class="column-icon">
        <v-icon>mdi-database</v-icon>
      </div>
      <div class="column-name text-truncate mr-1">
        {{ stage.name }}
      </div>
      <!-- {{ stage.position }} -->
      <slot name="menu"></slot>
    </div>

    <div v-show="!isHidden" style="min-height: 4px">
      <v-progress-linear
        v-if="pending"
        color="primary"
        indeterminate
        :width="2"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ColumnHeader",
  props: {
    pending: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: Object,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // handleEditClick() {
    //   this.editTitle = true;
    //   this.name = this.stage.name;
    // },
    // handleChangeTitle() {
    //   if (this.name === this.stage.name) {
    //     this.editTitle = false;
    //     return;
    //   }
    //   this.$emit("changeTitle", {
    //     name: this.name,
    //     id: this.stage.id,
    //   });
    //   this.editTitle = false;
    // },
  },
};
</script>

<style lang="scss" scoped>
$inner-gap-size: 10px !default;

.column-header {
  flex-grow: 0;
  margin-bottom: 5px;

  &.is-hidden {
    margin-bottom: 0 !important;
  }
}
.column-icon {
  flex-grow: 0;
  margin-right: 5px;
}

.is-hidden .column-name {
  display: none;
}
.is-hidden .column-status-bar {
  padding: 0 3px;
}
.column-title {
  position: relative;
  margin-top: 5px;
  margin-bottom: 5px;
  z-index: 1;
  padding: 0 10px;
  &__text {
    word-break: break-all;
  }

  &__text,
  &__area {
    font-family: inherit;
    font-size: 16px;
    line-height: 1.25;
    width: 100%;
  }

  &__area {
    border: 0 !important;
    box-shadow: none;
    resize: none;
    outline: none;
    padding: 8px;
    background-color: #fff;
    color: #000;
    border-radius: 4px;
  }
}

.column-status-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // margin: -$inner-gap-size;
  // padding: $inner-gap-size * 0.25 $inner-gap-size;
  background-color: fade-out(#000, 0.85);
  min-height: 41px;
  border-bottom: 1px;
  padding: 0 10px;
}

.column-delete {
  position: relative;
  z-index: 1;
}

.column-count {
  flex-grow: 0;
  font-size: 14px;
}

.column-name {
  color: #fff;
  display: block;
  font-size: 14px;
}
</style>
