<template>
  <div>
    <div
      ref="canban"
      class="canban scrollbars-canban"
      @wheel.passive="debouncedHandleScroll"
    >
      <div
        v-for="stage in stages"
        :key="stage.id"
        class="canban__stage canban__column"
        :data-stage="stage.id"
        :data-position="stage.position"
        :class="{
          'is-pending': pendingStages[stage.id],
          'is-hidden': hiddenColumns[stage.id],
        }"
      >
        <column-header
          :stage="stage"
          :title="stage.name"
          :pending="pendingStages[stage.id]"
          :is-hidden="hiddenColumns[stage.id]"
          @changeTitle="(d) => $emit('changeTitle', d)"
        >
          <template #menu>
            <v-menu
              v-if="!hiddenColumns[stage.id]"
              bottom
              left
              :disabled="pendingStages[stage.id]"
            >
              <template #activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="$emit('columnEdit', stage.id)">
                  <v-list-item-title
                    ><v-icon left>mdi-pencil</v-icon>Переименовать
                    колонку</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  v-if="!stage.initial"
                  @click.stop="$emit('setHidden', stage.id)"
                >
                  <v-list-item-title
                    ><v-icon left>mdi-eye-off</v-icon>Скрыть
                    колонку</v-list-item-title
                  >
                </v-list-item>

                <v-list-item
                  v-if="!stage.initial"
                  @click="handleRemoveColumn(stage.id)"
                >
                  <v-list-item-title
                    ><v-icon left>mdi-trash-can</v-icon>Удалить
                    колонку</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip v-else bottom color="primary">
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="$emit('setHidden', stage.id)"
                >
                  <v-icon color="white">mdi-eye-plus</v-icon>
                </v-btn>
              </template>
              <span>Показать колонку &laquo;{{ stage.name }}&raquo;</span>
            </v-tooltip>
          </template>
        </column-header>
        <sorted-cards
          v-show="!hiddenColumns[stage.id]"
          ref="stage_container"
          :stage-id="stage.id"
          :pending="Boolean(pendingStages[stage.id])"
          :items="items[stage.id]"
          :updated-cards="updatedCards"
          @cardClick="handleCardClick"
        />
      </div>
      <button
        v-ripple
        type="button"
        class="canban__stage canban__static canban__new"
        @click.prevent="handleAddStage"
      >
        <span v-if="pendingCreateColumn"
          ><v-progress-circular color="#fff" :indeterminate="true"
        /></span>
        <span v-else><v-icon dark>mdi-plus</v-icon>&nbsp;Добавить колонку</span>
      </button>
    </div>
    <div ref="mirror" class="canban-mirror"></div>
  </div>
</template>

<script>
import dragula from "dragula";
import "dragula/dist/dragula.min.css";
import debounce from "lodash/debounce";
import ColumnHeader from "@/components/canban/ColumnHeader";
import SortedCards from "@/components/canban/SortedCards";
const SCROLL_SENSITIVE_AREA = 100;
export default {
  name: "CanBan",
  components: {
    ColumnHeader,
    SortedCards,
  },
  props: {
    stages: {
      type: Array,
    },
    items: {
      type: Object,
    },
    changeItemPosition: {
      type: Function,
    },
    changeStagePosition: {
      type: Function,
    },
    addStage: {
      type: Function,
    },
    // removeStage: {
    //   type: Function,
    // },
    pendingStages: {
      type: Object,
    },
    isFiltersApplied: {
      type: Boolean,
      default: false,
    },
    updatedCards: {
      type: Object,
      default() {
        return {};
      },
    },
    hiddenColumns: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      stageDrake: null,
      cardDrake: null,
      pendingCreateColumn: false,
    };
  },
  computed: {
    debouncedHandleScroll() {
      return debounce(this.handleScroll, 300);
    },
    debouncedMove() {
      return debounce(this.handleScrollSenseArea, 500);
    },
    stageIds() {
      return this.stages.map((n) => n.id);
    },
  },
  watch: {
    stageIds(val, prev) {
      if (prev?.length) {
        const newStages = val.filter((n) => !prev.includes(n));
        this.$nextTick(() => {
          if (newStages?.length) {
            newStages.forEach((stageId) => {
              this.bindStageListeners(stageId);
            });
          }
        });
      }
    },
  },
  mounted() {
    this.setupDnD();
    this.stages.forEach((stage) => this.bindStageListeners(stage.id));
  },
  beforeDestroy() {
    document.removeEventListener("mousemove", this.debouncedMove);
    document.removeEventListener("keydown", this.titleEditListeners, false);
    // убиваем дрейки
    this.stageDrake?.destroy();
    this.cardDrake?.destroy();
  },
  methods: {
    bindStageListeners(stageId) {
      this.setupStageScroll(stageId);
      const ref = this.$refs.stage_container.find((n) => {
        return n.$options.propsData.stageId === stageId;
      });
      if (ref) {
        this.cardDrake.containers.push(ref.$el);
      }
    },
    handleCardClick(e, id) {
      this.$emit("cardClick", { id, ctrlKey: e.ctrlKey });
    },
    async handleRemoveColumn(id) {
      const removedColumn = this.stages.find((n) => n.id === id);
      const initialColumn = this.stages.find((n) => n.initial);
      const hasCards = this.items[id]?.length > 0;
      if (this.isFiltersApplied) {
        const confirm = await this.$root.$confirm(
          "Удаление колонки",
          "Чтобы удалить колонку, сначала нужно сбросить фильтры",
          {
            confirmText: "Сбросить фильтры",
            rejectText: "Нет, отмена",
          }
        );
        if (confirm) {
          this.$emit("handleClearFilters");
        }
        return;
      }
      if (hasCards) {
        const confirm = await this.$root.$confirm(
          "Удаление колонки",
          `Вы действительно хотите удалить колонку <strong>&laquo;${removedColumn.name}&raquo;</strong>? Карточки из этой колонки, будут перемещены в колонку <strong>&laquo;${initialColumn.name}&raquo;</strong>.`,
          {
            confirmText: "Да, удалить",
            rejectText: "Нет, отмена",
          }
        );
        if (confirm) {
          this.$emit("removeStage", id);
        }
      } else {
        this.$emit("removeStage", id);
      }
    },
    /**
     * Трансформирует mousewheel по доске
     * в горизонтальный скролл
     */
    handleScroll() {
      // const delta = e.deltaY;
      // const container = this.$refs.canban;
      // container.scrollLeft += delta;
      // if (e.target.classList.contains("canban")) {
      //   const delta = e.deltaY;
      //   const container = this.$refs.canban;
      //   container.scrollLeft += delta;
      // }
    },
    async onDropCard(el, target, source, sibling) {
      const nextId = sibling?.dataset?.contact
        ? +sibling.dataset.contact
        : null;
      const stage = +target.dataset.stage;
      const prevStage = +source.dataset.stage;
      const id = +el.dataset.contact;
      const cardNodes = Array.from(el.parentNode.querySelectorAll(".c-card"));
      const idx = cardNodes.indexOf(el);
      const prevId =
        idx > 0 ? Number(cardNodes[idx - 1]?.dataset.contact) : null;

      if (typeof this.changeItemPosition === "function") {
        try {
          await this.changeItemPosition({
            id,
            stage,
            nextId,
            prevId,
            prevStage,
          });
        } catch (error) {
          this.cardDrake.cancel(true);
        }
      }
    },
    async onDropStage(el) {
      const id = Number(el.dataset.stage);
      // новый индекс брошенной колонки
      // дожидаемся апдейта дома на дроп
      await this.$nextTick();
      const stages = Array.from(el.parentNode.children);
      const idx = stages.indexOf(el);
      // ID предыдущей колонки
      const prevId = idx > 0 ? Number(stages[idx - 1]?.dataset.stage) : null;
      // ID следующей колонки
      const nextId =
        idx < stages.length ? Number(stages[idx + 1]?.dataset.stage) : null;
      if (typeof this.changeStagePosition === "function") {
        try {
          await this.changeStagePosition({
            id: id,
            prev: prevId,
            next: nextId,
          });
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    /**
     * Обработчик движения мыши по доске,
     * если мы что-то перемещаем, и доходим
     * до левого или правого края доски, то нужно
     * отскролить контейнер
     */
    handleScrollSenseArea(e) {
      const { cardDrake, stageDrake } = this;
      /** быстрый выход если ничего не несем */
      if (!cardDrake.dragging && !stageDrake.dragging) return;
      const position = e.x;
      if (position < SCROLL_SENSITIVE_AREA) {
        return this.boardScroll(-window.innerWidth * 0.75);
      }
      if (position > window.innerWidth - SCROLL_SENSITIVE_AREA) {
        return this.boardScroll(window.innerWidth * 0.75);
      }
    },
    async handleAddStage() {
      if (this.pendingCreateColumn) return;
      this.pendingCreateColumn = true;
      const stage = await this.addStage();
      if (stage) {
        this.$nextTick(() => {
          this.$emit("columnEdit", stage.id);
          this.boardScroll("end");
          this.setupStageScroll(stage.id);
        });
      }
      this.pendingCreateColumn = false;
    },
    /**
     * Скролит доску влево или вправо
     * @param {'start'|'end'|number} amount start/and position or deltaX
     */
    boardScroll(amount) {
      const container = this.$refs.canban;
      if (typeof amount === "number") {
        container.scrollLeft += amount;
        return;
      }
      if (amount === "start") {
        container.scrollLeft = 0;
        return;
      }
      if (amount === "end") {
        container.scrollLeft = container.scrollWidth - container.clientWidth;
      }
    },
    // handleRemoveStage(id) {
    //   this.removeStage(id);
    //   this.$emit("removeStage", id);
    // },
    setupDnD() {
      /** drake для перетаскивания колонок (досок) */
      this.stageDrake = dragula([this.$refs.canban], {
        direction: "horizontal",
        mirrorContainer: this.$refs.mirror,
        revertOnSpill: true,
        accepts: (el, target, source, sibling) => {
          /**
           * `sibling` - здесь проверяю, чтобы нельзя было бросить
           * стейдж в самый конец, т.к. тогда он будет перед
           * блоком с "Добавить колонку"
           */
          return sibling && el.classList.contains("canban__column");
        },
        moves: (el, source, handle) => {
          // this.editTitle = null;
          // document.removeEventListener("keydown", this.titleEditListeners);
          /** Разрешаем перетаскивать, только если
           * вцепились не в карточку контакта
           */
          return (
            !handle.closest(".canban__static") &&
            !handle.closest(".canban-list__item")
          );
        },
      });

      /** drake для перетаскивания карточек контактов */

      this.cardDrake = dragula([], {
        mirrorContainer: this.$refs.mirror,
        accepts: (el) => {
          return el.classList.contains("canban-list__item");
        },
        moves: (el, source) => {
          // Запрещаем перетаскивать если колонка в статусе загрузки
          return !source.classList.contains("is-pending");
        },
        copy: false,
      });
      this.cardDrake.on("drop", this.onDropCard);
      this.stageDrake.on("drop", this.onDropStage);
      document.addEventListener("mousemove", this.debouncedMove);
    },
    /**
     * Устанавливает наблюдателя для скрола
     * переданной stage, чтобы дозапрашивать итемы,
     * когда мы проскролили в самый низ списка
     * @param {number} stageID
     * @returns {IntersectionObserver} observer
     */
    setupStageScroll(stageID) {
      const stageNode = document.querySelector(
        `.canban__stage[data-stage='${stageID}']`
      );
      if (!stageNode) return;
      const root = stageNode.querySelector(".canban-list");
      const options = {
        root,
        threshold: 0.5,
      };
      const target = root.querySelector(".s-loader");
      const callback = (entries) => {
        if (this.items[stageID] && entries[0].intersectionRatio > 0) {
          this.$emit("loadMore", stageID);
        }
      };
      const observer = new IntersectionObserver(callback, options);
      observer.observe(target);
      return observer;
    },
  },
};
</script>

<style lang="scss" scoped>
$gap-size: 10px !default;
$inner-gap-size: 10px !default;
.canban {
  display: flex;
  text-align: left;
  flex-flow: row nowrap;
  padding: $gap-size $gap-size $gap-size 0;
  margin-left: $gap-size;
  align-items: flex-start;
  overflow-x: auto;
  height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  // &::-webkit-scrollbar {
  //   width: 8px;
  //   height: 8px;
  //   border-radius: 8px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: fade-out(#000000, 0.8);
  // }

  // &::-webkit-scrollbar-thumb {
  //   box-shadow: none;
  //   background-color: #000;
  //   border-radius: 8px;
  // }

  &__list {
    min-height: 120px;
    height: 100%;
    padding-bottom: 10px;
    overflow-y: auto;
    color: #000;
    position: relative;
    z-index: 1;
  }

  &__item {
    margin-bottom: 4px;
    border-radius: 4px;
    margin-right: $inner-gap-size;
  }

  &__stage {
    // background-color: var(--v-primary-base);
    // background-color: #5c7382;
    // background-color: #90a4ae;
    background-color: #fff;
    color: #000;
    flex: 0 0 320px;
    // padding: $inner-gap-size 0 0 $inner-gap-size;
    overflow-y: auto;
    box-shadow: 0px 4px 5px -5px #000;
    border-radius: 4px;
    overflow: hidden;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    scroll-padding: 10px;
    & + & {
      margin-left: $gap-size;
    }

    &.is-hidden {
      width: 42px !important;
      flex-basis: 42px !important;
    }
  }

  &__new {
    background-color: fade-out(#000, 0.75);
    padding: 10px !important;
    transition: background 0.3s;
    color: #fff;

    span {
      display: inline-block;
      padding: 30px;
      border: 1px dashed #fff;
      border-radius: 4px;
      width: 100%;
    }

    &:hover {
      background-color: var(--v-primary-base);
    }

    &:active {
      transform: translateY(2px);
    }
  }

  &__header {
    flex-grow: 0;
    margin-bottom: 5px;
    padding-right: $inner-gap-size;
  }

  &__title {
    position: relative;
    margin-bottom: 5px;
    z-index: 1;
    &-text {
      word-break: break-all;
    }

    &-text,
    &-area {
      font-family: inherit;
      font-size: 16px;
      line-height: 1.25;
      width: 100%;
    }

    &-area {
      border: 0 !important;
      box-shadow: none;
      resize: none;
      outline: none;
      padding: 8px;
      background-color: #fff;
      color: #000;
      border-radius: 4px;
    }
  }

  &__status-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: -$inner-gap-size;
    margin-bottom: 10px;
    padding: $inner-gap-size * 0.25 $inner-gap-size;
    background-color: fade-out(#000, 0.85);
    min-height: 41px;
  }

  &__delete {
    position: relative;
    z-index: 1;
  }

  &__count {
    flex-grow: 0;
    font-size: 14px;
  }
}

.stage-loader {
  text-align: center;
}
</style>
