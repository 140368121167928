<template>
  <div
    ref="stage_container"
    class="canban-list scrollbars-canban"
    :data-stage="stageId"
    :class="{ 'is-pending': pending }"
  >
    <contact-card
      v-for="item in list"
      :key="item.id"
      class="drag-item canban-list__item"
      :data-contact="item.id"
      :updated="updatedCards[item.id]"
      :contact="item"
      @click.native="($event) => $emit('cardClick', $event, item.talent_id)"
    ></contact-card>
    <div style="height: 2px" class="s-loader"></div>
  </div>
</template>

<script>
import ContactCard from "@/components/canban/ContactCard.vue";
export default {
  name: "SortedCards",
  components: {
    ContactCard,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    pending: Boolean,
    stageId: Number,
    updatedCards: Object,
  },
  computed: {
    list() {
      return [...this.items].sort((prev, next) => {
        return prev.position > next.position
          ? 1
          : prev.position < next.position
          ? -1
          : 0;
      });
    },
  },
};
</script>

<style lang="scss">
.canban-list {
  min-height: 120px;
  height: 100%;
  // padding-bottom: 10px;
  overflow-y: auto;
  color: #000;
  position: relative;
  z-index: 1;
  padding: 0 10px 10px;
}

.canban-list__item {
  margin-bottom: 8px;
  border-radius: 4px;
  // margin-right: $inner-gap-size;
}
</style>
