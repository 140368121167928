<template>
  <div class="c-card" :class="{ 'is-updated': updated }">
    <div class="c-card__top">
      <div class="c-card__data">
        <div class="c-card__id">#{{ contact.talent_id }}</div>
        <!-- <div>{{ contact.position }}</div> -->
        <div class="c-card__name">{{ fullName || contact.talent_id }}</div>
      </div>

      <v-avatar
        v-if="avatar"
        class="c-card__avatar"
        darken
        size="30"
        elevation="1"
      >
        <img
          v-if="avatar"
          class="object-fit-cover"
          :src="avatar"
          :alt="fullName"
        />
      </v-avatar>
    </div>
    <div class="c-card__content">
      <template v-if="contact.user">
        <div class="text-truncate">{{ contact.user.email }}</div>
        <div v-if="contact.user.phone">
          {{ contact.user.phone }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { pathJoin } from "@/api/utils";
export default {
  name: "ContactCard",
  props: {
    contact: {
      type: Object,
    },
    updated: Boolean,
  },
  computed: {
    avatar() {
      const { contact } = this;
      if (!contact?.user?.avatar) return "";
      return pathJoin(process.env.VUE_APP_AVATAR_S3, contact.user.avatar);
    },
    fullName() {
      const { contact } = this;
      if (!contact || !contact.user) return "";
      return [
        contact.user.last_name,
        contact.user.first_name,
        contact.user.middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
  },
};
</script>

<style lang="scss" scoped>
.c-card {
  padding: 12px;
  background-color: #f2f2f2;
  // box-shadow: 0px 3px 6px -5px #000;
  box-shadow: -5px 7px 6px -9px #000;

  cursor: grab;

  &.is-updated {
    animation: highLight 1s linear both;
    animation-iteration-count: 1;
  }

  &.gu-mirror {
    transform: rotateZ(5deg);
    box-shadow: 2px 2px 4px -1px #000;
    cursor: grabbing;
  }

  &__top {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0px;
    line-height: 1.2;
  }

  &__data {
    flex-grow: 1;
    min-width: 1px;
  }

  &__content {
    font-size: 14px;

    a {
      text-decoration: none;
    }
  }

  &__id {
    font-size: 0.8em;
    margin-bottom: 0.2em;
    opacity: 0.7;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    flex-grow: 1;
    min-width: 1px;
  }

  &__avatar {
    flex-grow: 0;
    margin-left: 6px;
    font-size: 10px;
    color: #fff;
    margin-top: 5px;
  }
}

@keyframes highLight {
  from {
    background-color: #f2f2f2;
  }
  50% {
    background-color: var(--v-accent-lighten1);
  }
  to {
    background-color: #f2f2f2;
  }
}
</style>
