<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5 rm-dialog-title">{{
        isCompleted ? "Синхронизация завершена" : "Синхронизация не удалась"
      }}</v-card-title>
      <v-card-text class="black--text">
        <div v-if="isCompleted">
          <p>
            Синхронизация воронки с базой данных платформы Талант успешно
            завершена. Рекомендуем обновить страницу, чтобы получить актуальные
            данные.
          </p>
        </div>
        <div v-else>
          <p>
            Синхронизация воронки с базой данных платформы Талант завешилась с
            ошибкой. Для того, чтобы получить актуальные данные, попробуйте
            запусть синхронизацию еще раз. Если она снова завершиться с ошибкой,
            то сообщите об этом в техническую поддержку. Добавьте к своему
            сообщению следующие отладочные данные:
          </p>
          <p>
            ID Доски: {{ board }}, ID задачи на синхронизацию:
            {{ task && task.id }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="isCompleted" color="primary" @click="handleReload"
          >Обновить страницу</v-btn
        >
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TaskStatusDialog",
  props: {
    value: Boolean,
    task: {
      type: Object,
    },
    board: {
      type: Number,
    },
  },
  computed: {
    isCompleted() {
      return this.task?.status === "completed";
    },
  },
  methods: {
    handleReload() {
      window.location.reload();
    },
    handleClose() {
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
