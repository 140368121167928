import { Centrifuge } from "centrifuge";
let client = null;

/**
 *
 * @param {string} url
 * @param {string} token
 * @returns {Centrifuge} connection
 */
export const getConnection = (url, token) => {
  if (!client) {
    try {
      client = new Centrifuge(url, { debug: true, token });
      client.on("connecting", function (ctx) {
        console.log("connecting ctx", ctx);
      });
      client.on("error", function (ctx) {
        console.log("ctx error", ctx);
      });
      client.on("disconnected", function (ctx) {
        // do whatever you need in case of disconnect from server
        console.log("ctx disconnected", ctx);
      });
      // client.setToken(token);
      client.connect();
    } catch (error) {
      console.log("error", error);
    }
  }
  return client;
};
