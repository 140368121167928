<template>
  <v-container>
    <h1>{{ $metaInfo.title }}</h1>
    <v-progress-linear
      v-if="boardPending"
      color="primary"
      indeterminate
      :width="2"
    />
    <div v-else-if="error">
      <p>Не удалось получить данные о воронке. {{ error }}</p>
    </div>
    <div v-else class="mt-3">
      <board-form
        :key="key"
        ref="form"
        :submit-handler="updateBoard"
        :board="board"
      >
        <template #controls="{ pending }">
          <div class="mt-3">
            <v-btn type="submit" color="primary" class="mr-2" :loading="pending"
              >Сохранить изменения</v-btn
            >
            <v-btn
              type="button"
              color="primary"
              outlined
              :to="{ name: 'board', params: { id: $route.params.id } }"
              >Вернуться к воронке</v-btn
            >
          </div>
        </template>
      </board-form>
    </div>
  </v-container>
</template>

<script>
/**
 * @typedef listDiff
 * @prop {number[]} added
 * @prop {number[]} removed
 */
import { apiClient } from "@/api";
import BoardForm from "@/components/BoardForm.vue";
import difference from "lodash/difference";
// Карта названий модели и ключей в воронке
const MODEL_NAMES_MAP = {
  talent_ids: "talent_ids",
  tags: "tags",
  talent_brands: "brands",
  talent_routes: "routes",
};

const TALENT_GEO_KEYS = ["talent_regions", "talent_cities"];
/**
 * @param {(string|number)[]} source
 * @param {(string|number)[]} target
 * @returns {listDiff}
 */
const getDiff = (source, target) => {
  const result = {
    added: [],
    removed: [],
  };
  result.removed = difference(source, target);
  result.added = difference(target, source);
  if (!result.added.length && !result.removed.length) return undefined;
  return result;
};
const getUpdateValue = (source, target) => {
  if (target.length !== source?.length || difference(target, source).length) {
    return target;
  }
};

export default {
  name: "BoardsCreate",
  components: {
    BoardForm,
  },
  metaInfo() {
    return {
      title: "Редактировать воронку",
    };
  },
  async beforeRouteLeave(to, from, next) {
    try {
      const p = this.$refs.form?.$getFormPayload() || {};
      const diff = this.getUpdateDiff(p);
      if (Object.keys(diff).length) {
        const confirm = await this.$root.$confirm(
          "Выйти без сохранения",
          "Вы не сохранили изменения в форме. Выйти без сохранения?",
          {
            confirmText: "Да, выйти",
            rejectText: "Нет, отмена",
          }
        );
        if (!confirm) {
          this.$router.replace(from);
          return false;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
    next();
  },
  data() {
    return {
      board: null,
      key: 1,
      boardPending: true,
      error: "",
    };
  },
  created() {
    this.getBoard();
  },
  methods: {
    getUpdateDiff(payload) {
      const { board } = this;
      const result = {};
      if (!board || !payload) return result;
      Object.entries(payload).forEach(([key, value]) => {
        let diff = undefined;
        if (key === "name" && payload.name !== board.name) {
          result.name = value;
        } else if (
          ["talent_routes", "talent_brands", "talent_ids"].includes(key)
        ) {
          // Эти поля обновляются отдельными методами POST, DELETE
          // По этому нужно знать кто добавился кто удалился
          diff = getDiff(board[MODEL_NAMES_MAP[key]], value);
        } else if (["talent_regions", "talent_cities"].includes(key)) {
          // Эти поля обновляются в пач методе воронки
          diff = getUpdateValue(board[key], value);
        } else if (key === "tags") {
          // Это обновляется отдельными методами, просто нужно
          // теги tag[] мапнуть в id[]
          diff = getDiff(
            board[key]?.map((n) => n.id),
            value
          );
        }
        if (diff) {
          result[key] = diff;
        }
      });
      return result;
    },
    /**
     * @param {string} listType - Тип модели
     * @param {listDiff} diff - Изменения в списке
     * @returns {Promise<boolean>}
     */
    async updateList(listType, diff) {
      const endpoint = MODEL_NAMES_MAP[listType];
      let hasError = false;
      if (!diff || !endpoint) return hasError;
      const url = `/boards/${this.$route.params.id}/${endpoint}`;
      // Сначала удаляем элементы списка
      if (diff.removed.length) {
        try {
          await apiClient({
            method: "DELETE",
            url,
            data: {
              [endpoint]: diff.removed,
            },
          });
        } catch (error) {
          console.log("error", error);
          hasError = true;
          this.$toast(
            `Не удалось удалить элементы списка ${listType}. ${error.message}`,
            {
              type: "error",
            }
          );
        }
      }
      // После удаления можно добавить
      if (diff.added.length) {
        try {
          await apiClient({
            method: "POST",
            url,
            data: {
              [endpoint]: diff.added,
            },
          });
        } catch (error) {
          console.log("error", error);
          hasError = true;
          this.$toast(
            `Не удалось добавить элементы списка ${listType}. ${error.message}`,
            {
              type: "error",
            }
          );
        }
      }

      return hasError;
    },
    async updateBoard(payload) {
      const { board } = this;
      /**
       * Получаю разницу между было - стало
       * в формате [key]: {added: [], removed[]}
       * и name если поменялось
       */
      const diff = this.getUpdateDiff(payload);
      let hasError = false;

      // Объект с обновленными данными самой воронки
      const patchPayload = {};

      ["name", ...TALENT_GEO_KEYS].forEach((key) => {
        if (key in diff) {
          patchPayload[key] = diff[key];
        }
      });

      const boardUpdatedKeys = Object.keys(patchPayload);
      // Обновим саму воронку если что-то поменялось
      if (boardUpdatedKeys.length) {
        // Если не отправить имя в patch методе, то бек его скинет на пустую строку
        if (!patchPayload.name) {
          patchPayload.name = board.name;
        }
        try {
          await apiClient({
            method: "PATCH",
            url: `/boards/${board.id}`,
            data: patchPayload,
          });
        } catch (error) {
          hasError = true;
          this.$toast(`Не удалось обновить данные воронки. ${error.message}`, {
            type: "error",
          });
        }
      }

      // Список апдейтов было-стало для списочных параметров
      const listUpdates = [];

      Object.keys(MODEL_NAMES_MAP).forEach((key) => {
        if (key in diff) {
          console.log("diff[key]", diff[key]);
          listUpdates.push(this.updateList(key, diff[key]));
        }
      });
      const res = await Promise.all(listUpdates);
      if (!hasError) {
        hasError = res.some((n) => n === true);
      }

      // Если было обновление источников или фильтров
      // то нужно запросить синхронизацию
      if (
        listUpdates.length ||
        boardUpdatedKeys.some((key) => TALENT_GEO_KEYS.includes(key))
      ) {
        try {
          await apiClient({
            method: "POST",
            url: `/boards/${this.board.id}/tasks`,
          });
        } catch (error) {
          this.$toast(
            `Запрос на синхронизацию доски завершился с ошибкой! ${error.message}`,
            {
              type: "error",
            }
          );
        }
      }

      if (!hasError && (boardUpdatedKeys.length || listUpdates.length)) {
        this.$toast("Данные успешно обновлены", {
          type: "success",
        });
      }

      this.key += 1;
      this.getBoard();

      // // перекидываем юзера на созданную доску
      // this.$router.push({
      //   name: "board",
      //   params: {
      //     id: board.id,
      //   },
      // });
    },
    async getBoard() {
      const { id } = this.$route.params;
      this.boardPending = true;
      this.error = "";
      try {
        const { data } = await apiClient({
          method: "GET",
          url: `/boards/${id}`,
        });
        this.board = data;
      } catch (error) {
        console.log("error.status", error.status);
        if (error.status === 404) {
          this.$router.replace({
            name: "404",
            query: { from: this.$route.fullPath },
          });
          return;
        }
        this.error = error.messge;
      }
      this.boardPending = false;
    },
  },
};
</script>

<style></style>
