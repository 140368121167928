<template>
  <div class="not-found">
    <v-container>
      <h2>Воронка не найдена</h2>
      <p>Возможно ее удалили, или она никогда не существовала</p>
      <v-btn color="primary" :to="{ name: 'boards' }"
        >Вернуться к списку воронок</v-btn
      >
    </v-container>
  </div>
</template>

<script>
export default {
  name: "BoardNotFound",
};
</script>

<style lang="scss" scoped>
.not-found {
  margin-top: 40px;
}
</style>
