<template>
  <div class="sync-status">
    <div v-if="lastSync" class="mr-1">
      <div v-if="task.status === TASK_STATUS_COMPLETED">
        Синхронизировано {{ lastSync }}
      </div>
      <div v-else-if="task.status === TASK_STATUS_PENDING">
        Синхронизация #{{ task.id }} в очереди...
      </div>
      <div v-else-if="task.status === TASK_STATUS_RUNNING">
        Идет синхронизация...
      </div>
      <div v-else>Синхронизация #{{ task.id }} завершилась ошибкой</div>
    </div>
    <div v-else class="mr-1">Необходима синхронизация</div>
    <slot :loading="loading" :sync="handleSync"></slot>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { apiClient } from "@/api";
import {
  TASK_STATUS_COMPLETED,
  TASK_STATUS_RUNNING,
  TASK_STATUS_PENDING,
} from "@/constants";
export default {
  name: "TaskStatus",
  props: {
    board: Number,
    interval: {
      type: Number,
      default: 15000,
    },
  },
  data() {
    return {
      timeout: null,
      task: null,
    };
  },
  computed: {
    lastSync() {
      const { task } = this;
      const ts = this.$store.state.timeStamp;
      if (!task?.created_at) return undefined;
      /**
       * Какая то проблема на севрере с датой. Она почему-то иногда больше текущей. lol =)
       * по этому возвращаю "только что"
       */
      if (dayjs(task?.created_at) > ts) return "только что";

      return dayjs(task?.created_at).from(ts);
    },
    loading() {
      const { task } = this;
      if (!task) return false;
      return [TASK_STATUS_PENDING, TASK_STATUS_RUNNING].includes(task.status);
    },
  },
  watch: {
    board: {
      handler(val) {
        if (val) {
          this.getTask();
        }
      },
    },
  },
  created() {
    this.getTask();
    this.setTask2 = this.setTask;

    this.TASK_STATUS_COMPLETED = TASK_STATUS_COMPLETED;
    this.TASK_STATUS_RUNNING = TASK_STATUS_RUNNING;
    this.TASK_STATUS_PENDING = TASK_STATUS_PENDING;
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    runTimer() {
      this.timeout = setTimeout(this.getTask, this.interval);
    },
    async getTask() {
      clearTimeout(this.timeout);
      const { task } = this;
      const { data } = await apiClient({
        method: "GET",
        url: `/boards/${this.board}/tasks`,
        params: {
          limit: 1,
          offset: 0,
        },
      });
      const current = data.results[0];
      if (!current) return;
      // Если поменялся статус таски, то выбросим событие
      const isChangeStatus =
        task?.id === current.id && current.status !== task.status;
      const isCompleteNewSync =
        task &&
        current.id !== task.id &&
        current.status === TASK_STATUS_COMPLETED;
      if (isChangeStatus || isCompleteNewSync) {
        this.$emit("statusChange", current);
      }
      this.task = current;
      this.runTimer();
    },
    /**
     * ОТправляет запрос на синхронизацию
     */
    async handleSync() {
      clearTimeout(this.timeout);
      const { data } = await apiClient({
        method: "POST",
        url: `/boards/${this.board}/tasks`,
      });
      this.task = data;
      this.runTimer();
    },
    /**
     * Метод для апдейта таска по сокету.
     */
    // eslint-disable-next-line vue/no-unused-properties
    setTask(newTask) {
      if (!newTask || typeof newTask !== "object") return;
      const { task } = this;
      // Если ничего не поменялось, выходим
      if (task?.id === newTask.id && task.status === newTask.status) {
        return;
      }
      clearTimeout(this.timeout);
      this.task = newTask;
      this.$emit("statusChange", newTask);
      this.runTimer();
    },
  },
};
</script>

<style lang="scss" scoped>
.sync-status {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
