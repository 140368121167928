<template>
  <v-container>
    <h1>{{ $metaInfo.title }}</h1>
    <div class="mt-3">
      <board-form :submit-handler="createBoard" />
    </div>
  </v-container>
</template>

<script>
import { apiClient } from "@/api";
import BoardForm from "@/components/BoardForm.vue";

export default {
  name: "BoardsCreate",
  components: {
    BoardForm,
  },
  metaInfo() {
    return {
      title: "Создать воронку",
    };
  },
  methods: {
    async createBoard(payload) {
      console.log("payload", payload);
      const form = { ...payload };
      if (!form.talent_ids?.length) {
        delete form.talent_ids;
      }
      if (!form.tags?.length) {
        delete form.tags;
      }
      // создаем доску
      const { data: board } = await apiClient({
        method: "POST",
        url: "/boards",
        data: form,
      });
      // отправляем запрос на синхронизацию доски
      await apiClient({
        method: "POST",
        url: `/boards/${board.id}/tasks`,
      });
      // перекидываем юзера на созданную доску
      this.$router.push({
        name: "board",
        params: {
          id: board.id,
        },
      });
    },
  },
};
</script>

<style></style>
