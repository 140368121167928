export const SORT_OFFSET = 300;
export const INITIAL_SORT = 64000;
/**
 * Получает позицию колонки или карточки
 * для правильной сортировки. За основу взята логика у трелло
 * position = (prev+next)/2
 * @see https://stackoverflow.com/questions/14446859/what-does-the-pos-actually-mean-in-the-trello-api
 * @param {number=}  prevPos
 * @param {number=} nextPos
 * @param {number}  offset
 * @returns {number} position
 */
export const getPosition = (prevPos = 0, nextPos) => {
  // если список был пустой
  if (!nextPos && !prevPos) return INITIAL_SORT;
  // Если в конце списка
  if (!nextPos && prevPos) {
    return Math.max(Math.ceil(prevPos + INITIAL_SORT), INITIAL_SORT);
  }
  // Если в начале списка,
  if (!prevPos && nextPos) {
    return nextPos < SORT_OFFSET
      ? nextPos / 2
      : Math.ceil(nextPos - SORT_OFFSET);
  }
  // если разница между позициями больше 1
  // то среднее арифмитическое можно округлить
  // до целого значения, чтобы избегать дробей
  if (nextPos - prevPos > 2) {
    return Math.ceil((prevPos + nextPos) / 2);
  }
  return (prevPos + nextPos) / 2;
};
