<template>
  <div></div>
</template>

<script>
import { getConnection } from "./socketConnection";

const getUserID = (user) => {
  return Number(user.split("@")[0]);
};
export default {
  name: "SocketController",
  props: {
    boardId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    connection() {
      return getConnection(
        process.env.VUE_APP_CENTRIFUGE_CONNECT_URL,
        this.$store.state.user.token
      );
    },
  },
  async created() {
    /**
     * init channel and bind listeners
     */
    let channel = await this.getChannel();
    channel.on("publication", this.handleMessage);
    channel.on("join", this.handleJoinUsers);
    channel.on("leave", this.handleLeaveUser);
    channel.subscribe();
    this._channel = channel;
    try {
      const { clients } = await channel.presence();
      console.log("clients", clients);
      const ids = Object.values(clients).map((n) => getUserID(n.user));
      this.$emit("joinUsers", ids);
    } catch (err) {
      console.log("err", err);
    }
  },
  beforeDestroy() {
    try {
      this._channel.unsubscribe();
      this._channel.removeAllListeners();
    } catch (error) {
      console.log("unsubscribe error", error);
    }
  },
  methods: {
    async getChannel() {
      const channelName = `talent_crm:board${this.boardId}`;
      const existingChannel = await this.connection.getSubscription(
        channelName
      );
      if (!existingChannel) {
        return this.connection.newSubscription(channelName, {
          joinLeave: true,
        });
      }
      return existingChannel;
    },
    handleMessage(message) {
      const { data } = message;
      if (data?.type === "record" && data?.method === "PATCH") {
        // update карточки
        this.$emit("patchCard", data.payload);
      } else if (data?.type === "column" && data?.method === "PATCH") {
        // update колонки
        this.$emit("patchColumn", data.payload);
      } else if (data?.type === "column" && data?.method === "DELETE") {
        // update колонки
        this.$emit("deleteColumn", data.payload.id);
      } else if (data?.type === "column" && data?.method === "POST") {
        // Создание колонки
        this.$emit("createColumn", data.payload);
      } else if (data?.type === "sync") {
        this.$emit("syncUpdate", data.payload);
      }
    },
    handleJoinUsers(ctx) {
      const user = ctx?.info?.user;
      user && this.$emit("joinUsers", [getUserID(user)]);
    },
    handleLeaveUser(ctx) {
      console.log("ctx", ctx);
      const user = ctx?.info?.user;
      user && this.$emit("leaveUser", getUserID(user));
    },
  },
};
</script>

<style></style>
