<template>
  <div>
    <v-progress-circular
      v-if="boardLoading"
      class="mt-3"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div v-else-if="board" class="c-wrap" :style="{ height: wrapHeight }">
      <div class="c-wrap__top">
        <v-toolbar dense dark :color="toolbarColor">
          <v-btn
            tile
            fab
            dark
            small
            :color="toolbarColor"
            elevation="0"
            class="mr-2"
            to="/"
            title="Вернуться к списку воронок"
            ><v-icon>mdi-arrow-left</v-icon></v-btn
          >
          <div class="board-header__title">
            {{ board.name }} <span v-if="totalCount">({{ totalCount }})</span>
          </div>
          <v-spacer></v-spacer>
          <operators-list :ids="operators" />
          <task-status
            ref="taskStatus"
            :board="+$route.params.id"
            @statusChange="handleTaskStatusChange"
            @click.native="syncInfoDialog = true"
          >
            <template #default="{ loading, sync }">
              <v-btn
                tile
                fab
                dark
                small
                :color="toolbarColor"
                title="Запустить синхронизацию"
                elevation="0"
                :loading="loading"
                @click.stop="sync"
                ><v-icon>mdi-autorenew</v-icon></v-btn
              >
            </template>
          </task-status>
          <v-btn
            tile
            fab
            dark
            small
            :color="toolbarColor"
            title="Редактировать воронку"
            elevation="0"
            :to="{
              name: 'boards_update',
              params: {
                id: $route.params.id,
              },
            }"
            ><v-icon>mdi-database-cog</v-icon></v-btn
          >

          <v-btn
            tile
            fab
            dark
            small
            elevation="0"
            :color="isFiltersApplied ? 'white' : toolbarColor"
            title="Применить фильтры"
            @click="filterDialog = true"
          >
            <v-icon :color="isFiltersApplied ? toolbarColor : 'white'"
              >mdi-magnify</v-icon
            >
          </v-btn>
          <v-scroll-x-transition>
            <v-btn
              v-if="isFiltersApplied"
              tile
              fab
              dark
              small
              color="blue-grey darken-2"
              elevation="0"
              title="Сбросить фильтры"
              @click="handleClearFilters"
            >
              <v-icon>mdi-magnify-close</v-icon>
            </v-btn>
          </v-scroll-x-transition>
        </v-toolbar>
      </div>

      <can-ban
        v-if="columnsList.length"
        :key="canBanKey"
        class="c-wrap__content"
        :stages="columnsList"
        :change-item-position="changeCardPosition"
        :change-stage-position="changeColumnPosition"
        :add-stage="handleAddColumn"
        :items="records"
        :pending-stages="pendingStages"
        :is-filters-applied="isFiltersApplied"
        :updated-cards="socketUpdatedCards"
        :hidden-columns="hiddenColumns"
        @cardClick="handleCardClick"
        @loadMore="handleLoadMoreCards"
        @columnEdit="handleOpenColumnDialog"
        @removeStage="handleRemoveColumn"
        @handleClearFilters="handleClearFilters"
        @setHidden="setColumnHidden"
      />
      <edit-board-dialog
        v-model="editBoard"
        :board="board"
        @close="editBoard = false"
        @boardUpdate="handleBoardUpdate"
      />
      <v-dialog v-model="contactDialog" max-width="600px">
        <contact-dialog
          :is-active="contactDialog"
          :talent-id="selectedTalentId"
          @close="contactDialog = false"
        />
      </v-dialog>

      <user-filter-dialog
        v-model="filterDialog"
        :applied-filters="filters"
        :is-filters-applied="isFiltersApplied"
        @applyFilters="applyFilters"
      />
      <task-status-change
        v-model="statusChangeDialog"
        :board="+$route.params.id"
        :task="lastSyncTask"
      />
      <column-edit-dialog
        v-model="columnEditDialog"
        :column="selectedColumn"
        @onSubmit="patchColumn"
      />
      <sync-info-dialog v-model="syncInfoDialog" />
      <socket-controller
        :board-id="Number($route.params.id)"
        @joinUsers="handleJoinUsers"
        @leaveUser="handleLeaveUser"
        @patchColumn="handleSocketPatchColumn"
        @deleteColumn="handleSocketDeleteColumn"
        @createColumn="handleSocketCreateColumn"
        @patchCard="handleSocketPatchCard"
        @syncUpdate="handleSyncUpdate"
      />
    </div>
    <board-not-found v-else-if="notFound" />
    <div v-else class="mt-3">
      <v-container>
        <p>Ошибка при загрузке воронки. {{ boardError }}</p>
      </v-container>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { apiClient } from "@/api";
import { getPosition, INITIAL_SORT } from "@/utils/canban";
import { delay, numCases } from "@/utils";

import CanBan from "@/components/canban/CanBan";
import ContactDialog from "@/components/dialogs/ContactDialog.vue";
import UserFilterDialog from "@/components/dialogs/UserFilterDialog";
import TaskStatus from "@/components/TaskStatus";
import TaskStatusChange from "@/components/dialogs/TaskStatusChange";
import ColumnEditDialog from "@/components/dialogs/ColumnEditDialog";
import SyncInfoDialog from "@/components/dialogs/SyncInfoDialog";
import SocketController from "@/components/canban/SocketController.vue";
import OperatorsList from "@/components/canban/OperatorsList.vue";
import BoardNotFound from "@/components/BoardNotFound.vue";
const initialFilters = () => {
  return {
    age_max: null,
    age_min: null,
    city: "",
    region_with_type: "",
    search: "",
    sex: null,
  };
};
export default {
  name: "Board",
  components: {
    UserFilterDialog,
    CanBan,
    ContactDialog,
    TaskStatus,
    TaskStatusChange,
    ColumnEditDialog,
    SyncInfoDialog,
    SocketController,
    OperatorsList,
    BoardNotFound,
  },
  data() {
    return {
      boardLoading: true,
      boardError: "",
      toolbarColor: "primary",
      columnEditDialog: false,
      selectedColumn: null,
      statusChangeDialog: false,
      lastSyncTask: null,
      contactDialog: false,
      selectedTalentId: null,
      cardsPerRequestLimit: 41,
      canBanKey: 1,
      pendingStages: {},
      records: {},
      board: null,
      columns: {},
      // карта скрытых колонок
      hiddenColumns: {},
      // модалка с редактированием названия доски
      editBoard: false,
      pageTitle: "Страница вороки",
      filterDialog: false,
      filters: initialFilters(),
      syncInfoDialog: false,
      wrapHeight: "",
      operators: [],
      /** Список обновленных карточек в сокете
       * card_id: boolean, vue2 не поддерживает реактивные Set =(
       */
      socketUpdatedCards: {},
    };
  },
  metaInfo() {
    return {
      title: this.pageTitle,
      htmlAttrs: {
        class: "overflow-hidden",
      },
    };
  },
  computed: {
    columnsList() {
      // Сортируем колонки по возрастанию позиции
      return Object.values(this.columns).sort((a, b) => {
        return a.position - b.position > 0 ? 1 : -1;
      });
    },
    // wrapStyle() {
    //   return {
    //     height: `calc(100vh - ${this.$vuetify.application.top}px)`,
    //   };
    // },
    isFiltersApplied() {
      return Object.values(this.filters).some((filter) => filter);
    },
    // все загруженные карточки
    allCards() {
      return Object.values(this.records).reduce((all, value) => {
        return {
          ...all,
          ...value.reduce((acc, card) => {
            acc[card.id] = card;
            return acc;
          }, {}),
        };
      }, {});
    },
    searchParams() {
      const { filters } = this;
      return Object.keys(filters).reduce((acc, key) => {
        if (filters[key]) {
          acc[key] = filters[key];
        }
        return acc;
      }, {});
    },
    totalCount() {
      const count = this.board?.count_records;
      if (count >= 0) {
        return `${count} ${numCases(
          ["контакт", "контакта", "контактов"],
          count
        )}`;
      }
      return undefined;
    },
    throttledResize() {
      return debounce(this.handleSetWrapHeight, 500);
    },
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.init();
      },
    },
    filters: {
      deep: true,
      handler() {
        this.fetchColumnsCards({ isFilter: true });
        this.syncCanBan();
      },
    },
  },
  created() {
    this.init();
    this.handleSetWrapHeight();
    window.addEventListener("resize", this.throttledResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.throttledResize);
  },
  methods: {
    /**
     * Получение списка колонок
     */
    async getColumns() {
      const { id } = this.$route.params;
      const { data } = await apiClient({
        method: "GET",
        url: `/boards/${id}/columns`,
      });
      // складываю в объект, чтобы удобнее
      // было работать дальше
      if (Array.isArray(data.results)) {
        this.columns = data.results.reduce((acc, column) => {
          acc[column.id] = {
            ...column,
            last_item: null,
          };
          return acc;
        }, {});
      }
    },
    async getBoard() {
      const { id } = this.$route.params;
      const { data } = await apiClient({
        method: "GET",
        url: `/boards/${id}`,
      });
      this.board = {
        ...data,
        talent_regions: ["Свердловская обл"],
        talent_cities: ["Москва"],
        talent_routes: [2, 3, 4],
        talent_brands: [2, 3, 4],
      };
      this.pageTitle = data.name;
    },
    /**
     * force re-render компонента canban
     * в случае рассинхрона дом дерева и
     * данных в базе
     */
    syncCanBan() {
      this.canBanKey += 1;
    },
    /**
     * Перемещение карточки контакта
     * @param {object} item
     * @param {number} item.id - id перемещаемой карточки
     * @param {number} item.stage - id колонки куда переместили карточку
     * @param {number} item.prevStage - id исходной колонки
     * @param {number?} item.nextId - id следующей карточки
     * @param {number?} item.prevId - id предыдущей карточки
     */
    async changeCardPosition(item) {
      const { allCards } = this;
      const card = allCards[item.id];
      if (!card) return;
      const prevPosition = allCards[item.prevId]?.position;
      let nextPosition = allCards[item.nextId]?.position;
      /**
       * Если бросили в конец списка, то последнюю позицию
       * нужно смотреть по `last_item` у колонки
       */
      if (!item.nextId) {
        nextPosition = this.columns[item.stage].last_item?.position;
      }
      const position = getPosition(prevPosition, nextPosition);

      const payload = {
        column_id: item.stage,
        position,
      };
      const updateCard = {
        ...card,
        ...payload,
      };
      try {
        this.setColumnPending(item.stage, true);

        const oldIdx = this.records[item.prevStage].findIndex(
          (n) => n.id === item.id
        );

        this.records[item.prevStage].splice(oldIdx, 1);
        this.records[item.stage].push(updateCard);

        await apiClient({
          method: "PATCH",
          data: payload,
          url: `/columns/${item.prevStage}/records/${item.id}`,
        });
      } catch (error) {
        this.records[item.prevStage].push(card);

        this.syncCanBan();
        this._showError("Не удалось переместить карточку");
      }
      this.setColumnPending(item.stage);
    },
    /**
     * Изменение положения колонки
     * @param {object} data
     * @param {number} data.id - id текущей колонки
     * @param {number?} data.prev - id предыдущей колонки
     * @param {number?} data.next - id следующей колонки
     */
    async changeColumnPosition({ prev, id, next }) {
      const { columns } = this;
      const position = getPosition(
        columns[prev]?.position,
        columns[next]?.position
      );
      this.$set(this.pendingStages, id, true);
      try {
        await this.patchColumn(id, {
          position,
        });
      } catch (error) {
        this.syncCanBan();
        this._showError(`Не удалось переместить колонку. ${error.message}`);
      }
      this.$delete(this.pendingStages, id);
    },
    /**
     * Обновляет данные о колонке
     * @param {number} id колоки
     * @param {object} payload
     */
    async patchColumn(id, payload) {
      try {
        const { data } = await apiClient({
          method: "PATCH",
          data: payload,
          url: `/boards/${this.board.id}/columns/${id}`,
        });
        this.$set(this.columns, id, {
          ...this.columns[id],
          ...data,
        });
      } catch (error) {
        this._showError(`Не удалось обновить колонку`);
      }
    },

    /**
     * Удаление колокни
     * Если в колонке находились карточки, то они
     * все будут перенесены в `initial` колонку
     * @param {number} id колоки
     */
    async handleRemoveColumn(id) {
      try {
        this.setColumnPending(id, true);
        await apiClient({
          method: "DELETE",
          url: `/boards/${this.board.id}/columns/${id}`,
        });
        const hasRecords = this.records[id]?.length > 0;
        this.$delete(this.columns, id);
        this.$delete(this.records, id);
        // если колонка осталась одна,
        // то нормализуем ее позицию
        if (this.columnsList.length === 1) {
          this.patchColumn(this.columnsList[0].id, {
            position: INITIAL_SORT,
          });
        }
        // нужно заново получить контакты из начальной колонки
        // если у удаляемой колонки были карточки
        const init = this.columnsList.find((n) => n.initial);
        if (hasRecords && init) {
          this.getCards({ id: init.id, offset: 0 });
        }
      } catch (error) {
        this._showError(`Не удалось удалить колонку`);
      }
      this.setColumnPending(id, false);
    },

    /**
     * Создание новой колонки
     */
    async handleAddColumn() {
      const { columnsList } = this;
      const position = getPosition(
        columnsList[columnsList.length - 1]?.position
      );
      const { data } = await apiClient({
        method: "POST",
        url: `/boards/${this.board.id}/columns`,
        data: {
          name: "Новая колонка",
          position: position,
        },
      });
      const column = { ...data, last_item: null };
      this.$set(this.columns, column.id, column);
      this.$set(this.records, column.id, []);
      return column;
    },
    /**
     * Обработчик события, `Загрузить еще карточки`
     * @param {number} id - id колонки
     */
    handleLoadMoreCards(id) {
      const lastItem = this.columns[id].last_item;
      /**
       * Если колонка в статусе pending или нет
       * last_item (это означает, что все итемы из колонки загружены)
       */
      if (this.pendingStages[id] || !lastItem) return;
      let offset = this.records[id]?.length || 0;
      if (lastItem) {
        offset += 1;
      }
      this.getCards({ id: id, offset });
    },
    /**
     * Получение списка карточек
     * @param {object} params
     * @param {number} params.id - id колонки
     */
    async getCards(params) {
      const { id, isFilter, ...rest } = params;
      const { searchParams } = this;
      this.setColumnPending(id, true);
      const { data } = await apiClient({
        method: "GET",
        url: `/columns/${params.id}/records`,
        params: {
          limit: this.cardsPerRequestLimit,
          ...searchParams,
          ...rest,
        },
      });
      let items = [...data.results];
      /**
       * если уже был скрытый последний элемент
       * и мы фильтруем, то удалим его
       */
      if (this.columns[id].last_item && isFilter) {
        delete this.columns[id].last_item;
      }
      let newLastItem = null;
      /**
       * если записей много, то скрываем последнюю,
       * для того, чтобы правильно считать позиции
       * брошенных в конец списка карточек
       */
      if (items.length === this.cardsPerRequestLimit) {
        newLastItem = items.splice(-1, 1)[0];
      }

      /**
       * если уже был скрытый последний элемент
       * то добавим его в начало возвращаемого массива
       */
      if (this.columns[id].last_item) {
        items.unshift({ ...this.columns[id].last_item });
      }

      if (params.offset > 0) {
        items = [...this.records[id], ...items];
      }
      this.$set(this.records, id, items);
      this.columns[id].last_item = newLastItem;
      this.$delete(this.pendingStages, id);
      this.setColumnPending(id);
    },

    /**
     * Получает список задач на синхронизацию,
     * если синхронизация не завершена, то нужно
     * блокировать доску
     */
    setColumnPending(id, status) {
      if (status) {
        this.$set(this.pendingStages, id, true);
      } else {
        this.$delete(this.pendingStages, id);
      }
    },
    /**
     * Обработчик клика по карточке котакта
     * @param {object} payload
     * @param {number} id - id записи
     * @param {boolean} ctrlKey
     */
    handleCardClick(payload) {
      if (payload.ctrlKey) {
        this.$router.push({
          name: "contact",
          params: {
            id: payload.id,
          },
        });
      } else {
        this.selectedTalentId = payload.id;
        this.contactDialog = true;
      }
    },
    async init() {
      this.notFound = false;
      this.boardError = "";
      this.boardLoading = true;
      const hidden = localStorage?.getItem(
        `hidden_columns_${this.$route.params.id}`
      );
      if (hidden) {
        this.hiddenColumns = JSON.parse(hidden);
      }
      try {
        await this.getBoard();
        try {
          await this.getColumns();
        } catch (error) {
          this.boardError = error.message;
        }
      } catch (error) {
        if (error.status === 404) {
          this.notFound = true;
          this.boardLoading = false;
          this.boardError = error.message;
          return;
        }
        this.boardError = error.message;
      }
      this.boardLoading = false;
      this.fetchColumnsCards();
    },
    applyFilters(filters) {
      const isEmptyFilters = Object.keys(filters).every((key) => {
        return !filters[key];
      });
      if (!this.isFiltersApplied && isEmptyFilters) return;
      this.filters = { ...filters };
    },
    handleClearFilters() {
      this.filters = initialFilters();
    },
    fetchColumnsCards(params = {}) {
      this.columnsList.forEach((n) => {
        this.getCards({
          id: n.id,
          ...params,
        });
      });
    },
    handleTaskStatusChange(task) {
      if (!task || ["pending", "running"].includes(task?.status)) return;
      this.lastSyncTask = task;
      this.statusChangeDialog = true;
    },
    handleOpenColumnDialog(id) {
      this.selectedColumn = id
        ? this.columnsList.find((n) => n.id === id)
        : null;
      this.columnEditDialog = true;
    },
    handleSetWrapHeight() {
      this.wrapHeight = `${
        window.innerHeight - this.$vuetify.application.top
      }px`;
    },
    handleJoinUsers(ids) {
      this.operators = [...new Set([...this.operators, ...ids])];
    },
    handleLeaveUser(id) {
      const idx = this.operators.indexOf(id);
      if (idx >= 0) {
        this.operators.splice(idx, 1);
      }
    },
    /**
     * Апдейт колонки: перемещение, переименование
     */
    handleSocketPatchColumn(payload) {
      const { id, ...rest } = payload;
      const column = this.columns[id];
      if (!column) return;
      const isChanged = Object.keys(rest).some((key) => {
        return column[key] !== rest[key];
      });
      if (isChanged) {
        this.$set(this.columns, id, {
          ...this.columns[id],
          ...rest,
        });
        if (column.position !== rest.position) {
          this.syncCanBan();
        }
      }
    },
    handleSocketDeleteColumn(id) {
      const column = this.columns[id];
      if (!column) return;
      const hasRecords = this.records[id]?.length > 0;
      this.$delete(this.columns, id);
      this.$delete(this.records, id);
      const init = this.columnsList.find((n) => n.initial);
      if (hasRecords && init) {
        this.getCards({ id: init.id, offset: 0 });
      }
    },
    handleSocketCreateColumn(payload) {
      if (this.columns[payload.id]) return;
      const column = { ...payload, last_item: null, initial: false };
      this.$set(this.columns, column.id, column);
      this.$set(this.records, column.id, []);
    },
    handleSocketPatchCard(payload) {
      const current = this.allCards[payload.id];
      // если текущая карточка существует на доске
      if (current) {
        // Выходим если не произошло измененений
        // (автор сообщения текущий юзер)
        if (
          current.column_id === payload.column_id &&
          current.position === payload.position
        ) {
          return;
        }
        const currentIdx = this.records[current.column_id].findIndex((n) => {
          return n.id === payload.id;
        });
        this.records[current.column_id].splice(currentIdx, 1);
        // Проверим, что позиция текущей карточки не выходит за диапазон
        // пагинации. Если выходит - то не добавляем ее в колонку
        const lastPosition =
          this.columns[payload.column_id]?.last_item?.position;
        if (!lastPosition || lastPosition > payload.position) {
          this.records[payload.column_id].push({
            ...current,
            ...payload,
          });
        }
        this.$set(this.socketUpdatedCards, payload.id, true);
      } else {
        // Если такой карточки нет, то нужно ее добавить, если она не вываливается
        // за диапазон пагинации
        const column = this.columns[payload.column_id];
        if (!column) return;
        const lastPosition = column?.last_item?.position;
        if (!lastPosition || lastPosition > payload.position) {
          this.records[payload.column_id].push(payload);
          this.$set(this.socketUpdatedCards, payload.id, true);
        }
      }
      delay(2000).then(() => {
        this.$delete(this.socketUpdatedCards, payload.id);
      });
    },
    handleSyncUpdate(task) {
      console.log("task", task);
      this.$refs.taskStatus?.setTask2(task);
    },
    /**
     * Обновление настроек доски
     * @param {object} payload
     * @param {object[]} payload.tags
     * @param {string=} payload.name
     */
    async handleBoardUpdate(payload) {
      if (!payload || typeof payload !== "object") return;
      // Обновляем имя доски
      if (payload.name && this.board.name !== payload.name) {
        apiClient({
          method: "PATCH",
          url: `/boards/${this.board.id}`,
          data: {
            name: payload.name,
          },
        })
          .then(() => {
            this.board.name = payload.name;
          })
          .catch((error) => {
            this.$toast(`Не удалось переименовать доску ${error.message}`, {
              type: "error",
            });
          });
      }
      const udatedTags = await this.updateBoardTags(payload.tags);
      const updateList = await this.updateBoardTalentIds(payload.ids);
      // Если обновились теги или обновился список id
      // то нужно запросить синк доски
      if (udatedTags || updateList) {
        apiClient({
          method: "POST",
          url: `/boards/${this.board.id}/tasks`,
        });
      }
    },
    async updateBoardTags(tags) {
      // Что-то не то передали
      if (!tags || !Array.isArray(tags)) return false;
      // Нечего обновлять
      if (!tags.length && !this.board.tags) return false;

      const requests = [];
      const oldTags = this.board.tags || [];
      const removedTags = oldTags.filter((tag) => {
        return !tags.some((t) => t.id === tag.id);
      });
      const addedTags = tags.filter((tag) => {
        return !oldTags.some((t) => t.id === tag.id);
      });
      const url = `/boards/${this.board.id}/tags`;

      if (removedTags.length) {
        requests.push(
          apiClient({
            method: "DELETE",
            url,
            data: {
              tags: removedTags.map((n) => n.id),
            },
          })
        );
      }
      if (addedTags.length) {
        requests.push(
          apiClient({
            method: "POST",
            url,
            data: {
              tags: addedTags.map((n) => n.id),
            },
          })
        );
      }

      if (requests.length) {
        try {
          await Promise.all(requests);
          this.$set(this.board, "tags", [...tags]);
          return true;
        } catch (error) {
          console.log("error", error);
          return false;
        }
      }
      return false;
    },
    /**
     * @param {object} payload
     * @param {number[]} payload.added
     * @param {number[]} payload.removed
     * @returns {Boolean}
     */
    async updateBoardTalentIds(payload) {
      if (!payload || typeof payload !== "object") return;
      if (!payload.added.length && !payload.removed.length) return;
      const reqs = [];
      const url = `/boards/${this.board.id}/talent_ids`;

      /**
       * Сначала удаляем, чтобы была возможность добавить
       */
      if (payload.removed?.length) {
        reqs.push(
          apiClient({
            method: "DELETE",
            url,
            data: { talent_ids: payload.removed },
          })
        );
      }
      /**
       * Добавляем новые записи
       */
      if (payload.added?.length) {
        reqs.push(
          apiClient({
            method: "POST",
            url,
            data: { talent_ids: payload.added },
          })
        );
      }

      if (reqs.length) {
        try {
          await Promise.all(reqs);
          return true;
        } catch (error) {
          this.$toast(`Не удалось обновить список. ${error.message}`, {
            type: "error",
          });
        }
      }
      return false;
    },
    setColumnHidden(id) {
      const { hiddenColumns } = this;
      if (id in hiddenColumns) {
        this.$set(this.hiddenColumns, id, !hiddenColumns[id]);
      } else {
        this.$set(this.hiddenColumns, id, true);
      }
      localStorage?.setItem(
        `hidden_columns_${this.$route.params.id}`,
        JSON.stringify(this.hiddenColumns)
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.c-wrap {
  display: flex;
  width: 100%;
  height: 100vh;
  height: calc(100vh - 90px);
  flex-direction: column;

  &__top {
    flex-grow: 0;
  }

  &__content {
    flex-grow: 1;
    min-height: 1px;
  }
}
</style>
