<template>
  <div>
    <validation-observer ref="form" tag="form" @submit.prevent="handleSubmit">
      <v-row>
        <v-col md="6">
          <validation-provider
            v-slot="{ errors }"
            :rules="boardNameRules"
            name="name"
          >
            <v-text-field
              v-model="boardName"
              :error-messages="errors"
              label="Назание воронки*"
              solo
              hint="Укажите название воронки"
              persistent-hint
            ></v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
      <h3><strong>Источник данных</strong></h3>
      <v-row>
        <v-col md="6" xs="12">
          <p class="text-body-2">
            Укажите из каких источников данных, должна сформироваться воронка.
            Необходимо указать минимум 1 источник.
          </p>
        </v-col>
      </v-row>
      <v-expansion-panels class="mb-6">
        <v-expansion-panel v-if="!board || (board && board.talent_query_id)">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-avatar
              size="36"
              :color="selectedQuery ? 'primary' : 'blue-grey lighten-2'"
              class="flex-grow-0 mr-2"
            >
              <v-icon dark>mdi-database</v-icon> </v-avatar
            ><strong>SQL Запрос</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Заранее подготовленный SQL запрос в&nbsp;админке Таланта. Если
                  контакт в воронке, перестанет удовлетворять условиям запроса,
                  то он при синхронизации будет удален из этой воронки.
                  Вы&nbsp;можете выбрать один из&nbsp;существующих запросов или
                  обратиться к&nbsp;администраторам с&nbsp;просьбой добавить
                  новый запрос.
                  <strong
                    >Этот парметр нельзя изменить после создания доски</strong
                  >
                </p>
              </v-col>
            </v-row>

            <validation-provider
              v-if="!board"
              v-slot="{ errors }"
              :rules="{
                numeric: true,
              }"
              vid="talent_query_id"
              name="talent_query_id"
            >
              <v-autocomplete
                v-model="selectedQuery"
                :items="dbQueries.items"
                :loading="dbQueries.pending"
                :search-input.sync="searchQuery"
                :error-messages="errors"
                outlined
                dense
                clearable
                item-text="name"
                item-value="id"
                label="Query-запрос*"
                hint="Начните вводить название запроса"
                persistent-hint
              >
              </v-autocomplete>
            </validation-provider>
            <div v-else>Запрос #{{ board.talent_query_id }}</div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="selectedTags.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="selectedTags.length ? 'primary' : 'blue-grey lighten-2'"
              >
                <v-icon dark> mdi-tag-multiple </v-icon>
              </v-avatar>
            </v-badge>
            <strong>Список Тегов</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Укажите список тегов. В воронку будут добавляться все
                  пользователи, которые отмечены этим тегом. Если с пользователя
                  будет снят тег из этого списока, то он при синхронизации
                  удалится из воронки.
                </p>
              </v-col>
            </v-row>
            <v-chip
              v-for="item in selectedTags"
              :key="item.id"
              :color="item.color"
              class="mr-2 mb-2"
              dark
              small
            >
              {{ item.name }}
              <v-icon
                right
                size="18px"
                title="Удалить тег"
                @click="handleRemoveTag(item)"
                >mdi-close</v-icon
              >
            </v-chip>
            <div v-if="!selectedTags.length" class="text-caption mb-3">
              Теги не выбраны
            </div>
            <tag-search
              class="mt-3"
              :selected-tags="selectedTags"
              :allow-add-new="false"
              @tagClick="handleAddTag"
            >
              <template #title>
                <div></div>
              </template>
            </tag-search>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="ids.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="ids.length ? 'primary' : 'blue-grey lighten-2'"
              >
                <v-icon dark> mdi-playlist-check </v-icon>
              </v-avatar> </v-badge
            ><strong>Список ID пользователей</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Вы можете указать список id пользователей таланта, которые
                  должны быть в этой воронке. Каждый элемент списка, должен быть
                  целочисленным значением и разделен переносом строки.
                  Максимальное количество элементов в списке:
                  <strong>{{ MAX_BOARD_ID_LIST }}</strong>
                </p>

                <div v-if="boardHasIdsList" class="text-body-2">
                  <div>
                    У этой доски уже задан список ID пользователей состоящий из
                    {{ board.talent_ids.length }} talent_id.
                    <a href="#" @click.prevent="handleExport">Скачать список</a
                    >.<br /><br />
                    Как вы хотите обновить этот список?
                  </div>
                  <v-radio-group v-model="listUpdateVariant" column>
                    <v-radio
                      label="Дополнить существующий список указанными talent_id."
                      :value="IDS_UPDATE_MODE"
                    ></v-radio>
                    <v-radio
                      label="Полностью заменить существующий список новыми значениями talent_id"
                      :value="IDS_REPLACE_MODE"
                    ></v-radio>
                    <v-radio
                      label="Удалить все записи в списке"
                      :value="IDS_DELETE_MODE"
                    ></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>

            <validation-provider
              v-if="listUpdateVariant !== IDS_DELETE_MODE"
              v-slot="{ errors }"
              name="list"
            >
              <v-textarea
                v-model="idsList"
                outlined
                dense
                label="Talent_ID, разделенные переносом строки"
                :hint="`Добавлено ${ids.length} ID из
              ${maxIds}`"
                persistent-hint
                :error-messages="errors"
              ></v-textarea>
            </validation-provider>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h3><strong>Сужение воронки (фильтр)</strong></h3>
      <v-row>
        <v-col md="6" xs="12">
          <p class="text-body-2">
            Укажите параметры, по которым будут отфильтрованы контакты из
            источников.
          </p>
        </v-col>
      </v-row>

      <v-expansion-panels class="mb-6">
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="selectedRegions.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="
                  selectedRegions.length ? 'primary' : 'blue-grey lighten-2'
                "
              >
                <v-icon dark> mdi-map-marker-multiple </v-icon>
              </v-avatar> </v-badge
            ><strong>Фильтр по региону</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Укажите один или несколько регионов. Пользователи из других
                  регионов будут отфильтрованы.
                </p>
              </v-col>
            </v-row>
            <div v-if="selectedRegions.length" class="mb-4">
              <div class="mb-2">Выбранные регионы</div>
              <v-chip
                v-for="item in selectedRegions"
                :key="item"
                small
                class="mr-2 mb-2"
                >{{ item
                }}<v-icon
                  right
                  size="18px"
                  title="Удалить регион"
                  @click="handleRemoveRegion(item)"
                  >mdi-close</v-icon
                ></v-chip
              >
            </div>
            <v-autocomplete
              v-model="selectedRegions"
              :loading="regionsLoading"
              :items="regions"
              :search-input.sync="regionQuery"
              dense
              no-filter
              outlined
              multiple
              label="Регион пользователя"
              hint="Начните вводить название региона (минимум 3 символа)"
              persistent-hint
              @update:search-input="debouncedRegionSearch"
            >
              <template #selection></template>
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- Фильтр по региону  -->
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="selectedCities.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="
                  selectedCities.length ? 'primary' : 'blue-grey lighten-2'
                "
              >
                <v-icon dark>mdi-map-marker</v-icon>
              </v-avatar> </v-badge
            ><strong>Фильтр по городу</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Укажите один или несколько городов. Если указаны регионы, то
                  фильтр по городам будет расширять выборку. Т.е работает как
                  ИЛИ. (регион N или Город K)
                </p>
              </v-col>
            </v-row>
            <div v-if="selectedCities.length" class="mb-4">
              <div class="mb-2">Выбранные города</div>
              <v-chip
                v-for="item in selectedCities"
                :key="item"
                small
                class="mr-2 mb-2"
                >{{ item
                }}<v-icon
                  right
                  size="18px"
                  title="Удалить город"
                  @click="handleRemoveCity(item)"
                  >mdi-close</v-icon
                ></v-chip
              >
            </div>
            <v-autocomplete
              v-model="selectedCities"
              :loading="cityLoading"
              :items="cities"
              :search-input.sync="cityQuery"
              item-text="title"
              item-value="value"
              dense
              no-filter
              outlined
              multiple
              label="Город пользователя"
              hint="Начните вводить название города (минимум 3 символа)"
              persistent-hint
              @update:search-input="debouncedCitySearch"
            >
              <template #selection></template>
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- /Фильтр по городу  -->
        <v-expansion-panel :disabled="initialBrandsLoading">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="selectedBrands.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="
                  selectedBrands.length ? 'primary' : 'blue-grey lighten-2'
                "
              >
                <v-icon dark>mdi-star-circle</v-icon>
              </v-avatar> </v-badge
            ><strong>Фильтр по бренду мероприятия</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Укажите один или несколько брендов. В воронке останутся
                  пользователи, участвовавшие в мероприятиях этих брендов.
                </p>
              </v-col>
            </v-row>
            <div v-if="selectedBrands.length" class="mb-4">
              <div class="mb-2">Выбранные бренды</div>
              <v-chip
                v-for="item in selectedBrands"
                :key="item.id"
                small
                class="mr-2 mb-2"
                >{{ item.title
                }}<v-icon
                  right
                  size="18px"
                  title="Удалить бренд"
                  @click="handleRemoveBrand(item)"
                  >mdi-close</v-icon
                ></v-chip
              >
            </div>
            <v-autocomplete
              v-model="selectedBrands"
              :loading="brandsLoading"
              :items="brands"
              :search-input.sync="brandsQuery"
              dense
              item-text="title"
              item-value="id"
              outlined
              multiple
              label="Бренды"
              return-object
              hint="Начните вводить название бренда, минимум 3 символа"
              persistent-hint
              @update:search-input="debouncedBrandsSearch"
            >
              <template #selection></template>
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- /Фильтр по Бренду  -->
        <v-expansion-panel :disabled="routesLoading">
          <v-expansion-panel-header expand-icon="mdi-menu-down">
            <v-badge
              class="flex-grow-0 mr-3"
              overlap
              color="blue-grey darken-3"
              :content="selectedRoutes.length || '0'"
              bottom
            >
              <v-avatar
                size="36"
                :color="
                  selectedRoutes.length ? 'primary' : 'blue-grey lighten-2'
                "
              >
                <v-icon dark>mdi-routes</v-icon>
              </v-avatar> </v-badge
            ><strong>Фильтр по направлению мероприятия</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col md="8" xs="12">
                <p class="text-body-2">
                  Укажите одно или более направлений, пользователи, в воронке
                  останутся участвовавшие в мероприятиях по этим направлениям
                </p>
              </v-col>
            </v-row>
            <div v-if="selectedRoutes.length" class="mb-4">
              <div class="mb-2">Выбранные направления</div>
              <v-chip
                v-for="item in mappedRoutes"
                :key="item.id"
                small
                class="mr-2 mb-2"
                >{{ item.title
                }}<v-icon
                  right
                  size="18px"
                  title="Удалить направление"
                  @click="handleRemoveRoute(item.id)"
                  >mdi-close</v-icon
                ></v-chip
              >
            </div>
            <v-autocomplete
              v-model="selectedRoutes"
              :items="routes"
              dense
              item-text="title"
              item-value="id"
              outlined
              multiple
              label="Направления"
              hint="Начните вводить название, минимум 3 символа"
              persistent-hint
            >
              <template #selection></template>
            </v-autocomplete>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- /Фильтр по направлению  -->
      </v-expansion-panels>

      <div v-if="error" class="error--text my-3">{{ error }}</div>

      <slot name="controls" :pending="pending">
        <v-btn type="submit" color="primary" class="mt-3" :loading="pending"
          >Создать воронку</v-btn
        >
      </slot>
    </validation-observer>
  </div>
</template>

<script>
import { talentClient } from "@/api";
import debounce from "lodash/debounce";
import {
  MAX_BOARD_ID_LIST,
  MIN_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH,
} from "@/constants";
import TagSearch from "@/components/TagSearch";
import { downloadSheet } from "@/utils/xlsx";

const IDS_UPDATE_MODE = "update";
const IDS_CREATE_MODE = "create";
const IDS_DELETE_MODE = "delete";
const IDS_REPLACE_MODE = "replace";

const brandMapper = (brand) => {
  return {
    title: brand.title,
    id: brand.id,
  };
};
export default {
  name: "BoardForm",
  components: {
    TagSearch,
  },
  props: {
    submitHandler: {
      type: Function,
      required: true,
    },
    board: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pending: false,
      error: "",
      searchQuery: "",
      idsList: "",
      selectedTags: [],
      selectedQuery: null,
      boardName: "",
      listUpdateVariant: IDS_CREATE_MODE,

      dbQueries: {
        search: "",
        page: 1,
        size: 40,
        pending: false,
        error: "",
        items: [],
        total: 0,
      },
      /**
       * @type {string[]}
       */
      selectedRegions: [],
      regionQuery: "",
      regions: [],
      regionsLoading: false,
      /**
       * @type {string[]}
       */
      selectedCities: [],
      cityQuery: "",
      cities: [],
      cityLoading: false,
      /**
       * @type {{id: number, title: string}[]}
       */
      selectedBrands: [],
      brandsQuery: "",
      brands: [],
      brandsLoading: false,
      /**
       * @type {number[]}
       */
      selectedRoutes: [],
      routesLoading: false,

      initialBrandsLoading: false,
    };
  },
  computed: {
    routes() {
      return this.$store.state.talent.allRoutes;
    },
    boardHasIdsList() {
      return this.board?.talent_ids?.length > 0;
    },
    maxIds() {
      const boardIds = this.board?.talent_ids?.length || 0;
      if (this.listUpdateVariant === IDS_UPDATE_MODE) {
        return MAX_BOARD_ID_LIST - boardIds;
      }
      return MAX_BOARD_ID_LIST;
    },
    ids() {
      const { idsList, boardHasIdsList, listUpdateVariant } = this;
      if (
        (!idsList && !boardHasIdsList) ||
        listUpdateVariant === IDS_DELETE_MODE
      ) {
        return [];
      }
      const result = new Set();
      const arr = idsList.split("\n").filter(Boolean);
      arr.reduce((acc, value) => {
        const num = parseInt(value, 10);
        if (value >= 0 && value < 1e8) {
          acc.add(num);
        }
        return acc;
      }, result);
      const current = [...result];
      // Если режим апдейта, то нужно вернуть
      // объединение текущего и this.board.talent_ids
      if (listUpdateVariant == IDS_UPDATE_MODE) {
        return [...current, ...this.board.talent_ids];
      }
      return current;
    },
    boardNameRules() {
      return {
        required: true,
        min: MIN_STRING_LENGTH,
        max: MAX_LONG_STRING_LENGTH,
      };
    },
    mappedRoutes() {
      return this.selectedRoutes.map((id) => {
        const route = this.routes.find((n) => n.id === id) || {
          id: id,
          title: id,
        };
        return route;
      });
    },
  },
  watch: {
    board: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.mapBoardToForm();
        }
      },
    },
    searchQuery: {
      handler() {
        this.debouncedQuerySearch();
      },
    },
  },
  created() {
    this.IDS_UPDATE_MODE = IDS_UPDATE_MODE;
    this.IDS_CREATE_MODE = IDS_CREATE_MODE;
    this.IDS_DELETE_MODE = IDS_DELETE_MODE;
    this.IDS_REPLACE_MODE = IDS_REPLACE_MODE;
    this.MAX_BOARD_ID_LIST = MAX_BOARD_ID_LIST;
    this.debouncedQuerySearch = debounce(this.getQueries, 400);
    this.debouncedRegionSearch = debounce(this.getRegions, 600);
    this.debouncedCitySearch = debounce(this.getCities, 600);
    this.debouncedBrandsSearch = debounce(this.getBrands, 600);
    this.getQueries();
    this.getRoutes();
    // Экспоузим метод наружу, чтобы при смене роута,
    // проверять сохранили или нет изменения
    this.$getFormPayload = this.getFormPayload;
  },
  methods: {
    handleAddTag(tag) {
      this.selectedTags.push(tag);
    },
    handleRemoveTag(tag) {
      const idx = this.selectedTags.findIndex(({ id }) => id === tag.id);
      if (idx !== -1) {
        this.$delete(this.selectedTags, idx);
      }
    },
    /**
     * Получение списка квери моделей для воронки
     */
    async getQueries() {
      this.dbQueries.pending = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: "/talent-sql-queries/",
          params: {
            search: this.searchQuery,
            page: this.dbQueries.page,
            size: this.dbQueries.size,
          },
        });
        this.dbQueries.items = data.results;
      } catch (error) {
        this.$refs.form.setErrors({
          talent_query_id: [`Не удалось получить список: ${error.message}`],
        });
      }
      this.dbQueries.pending = false;
    },
    /**
     * Отправка формы
     */
    async handleSubmit() {
      if (this.pending) return;
      const { ids, selectedTags, selectedQuery } = this;
      this.error = "";
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      const emptySource = !ids.length && !selectedTags.length && !selectedQuery;

      if (emptySource) {
        this.error = "Необходимо выбрать источник данных";
        return;
      }

      if (ids.length > this.maxIds) {
        this.$refs.form.setErrors({
          list: [`Количество ID должно быть меньше чем ${MAX_BOARD_ID_LIST}`],
        });
        return;
      }
      this.pending = true;
      const payload = this.getFormPayload();
      try {
        await this.submitHandler(payload);
      } catch (error) {
        console.log("error", error);
        this.error = error.message;
      }
      this.pending = false;
    },
    /**
     * Выгрузка списка talent_ids в excel
     */
    async handleExport() {
      if (!this.board.talent_ids?.length) {
        this.$toast("Список talent_id пользователей пуст!", {
          type: "error",
        });
        return;
      }
      const list = [...this.board.talent_ids].sort((a, b) => {
        return a - b;
      });
      try {
        await downloadSheet(
          ["Talent ID"],
          list.map((id) => [id]),
          `Список пользователей воронки ${this.board?.name}.`,
          "Список ID пользователей"
        );
      } catch (error) {
        let msg = "не удалось скачать таблицу.";
        if (error?.message) {
          msg = `${msg} ${error.message}.`;
        }
        this.$toast(msg, { type: "error" });
      }
    },
    mapBoardToForm() {
      const { board } = this;
      if (!board) return;
      this.boardName = board.name;
      if (board.tags?.length) {
        this.selectedTags = [...board.tags];
      }
      this.selectedQuery = board.talent_query_id || null;
      this.listUpdateVariant = this.boardHasIdsList
        ? IDS_UPDATE_MODE
        : IDS_CREATE_MODE;

      if (board.talent_regions?.length) {
        this.selectedRegions = [...board.talent_regions];
      }
      if (board.talent_cities?.length) {
        this.selectedCities = [...board.talent_cities];
      }
      if (board.routes?.length) {
        this.selectedRoutes = [...board.routes];
      }
      this.getInitialBrands(board.brands);
    },
    async getRegions() {
      const { regionQuery } = this;
      if (this.regionsLoading || !regionQuery?.length || regionQuery.length < 3)
        return;
      this.regionsLoading = true;
      try {
        const { data } = await talentClient({
          method: "POST",
          url: "/geo/suggest/",
          data: {
            query: regionQuery,
            to_bound: { value: "region" },
            from_bound: { value: "region" },
          },
        });
        console.log("data", data);
        if (Array.isArray(data)) {
          this.regions = data.map((n) => n.value);
        } else {
          this.regions = [];
        }
      } catch (error) {
        this.$toast(`Не удалось найти регион. ${error.message}`, {
          type: "error",
        });
      }
      this.regionsLoading = false;
    },
    async getCities() {
      const { cityQuery } = this;
      if (this.cityLoading || !cityQuery?.length || cityQuery.length < 3)
        return;
      this.cityLoading = true;
      try {
        const { data } = await talentClient({
          method: "POST",
          url: "/geo/suggest/",
          data: {
            query: cityQuery,
            to_bound: { value: "city" },
            from_bound: { value: "city" },
          },
        });
        if (Array.isArray(data)) {
          this.cities = data.map((n) => {
            return {
              title: n.value,
              value: n.data.city,
            };
          });
        } else {
          this.cities = [];
        }
      } catch (error) {
        this.$toast(`Не удалось найти город ${cityQuery}. ${error.message}`, {
          type: "error",
        });
      }
      this.cityLoading = false;
    },
    async getBrands() {
      const { brandsQuery } = this;
      if (this.brandsLoading) return;
      this.brandsLoading = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: "/brands/",
          params: {
            search: brandsQuery,
            limit: 20,
            offset: 0,
          },
        });
        if (Array.isArray(data.results)) {
          this.brands = data.results.map(brandMapper);
        } else {
          this.brands = [];
        }
      } catch (error) {
        this.$toast(`Не удалось найти бренд ${brandsQuery}. ${error.message}`, {
          type: "error",
        });
      }
      this.brandsLoading = false;
    },
    /**
     * Получение списка брендов, которые назначены
     * к доске, чтобы сериализовать значения
     */
    async getInitialBrands(ids) {
      if (!ids?.length) return;
      this.initialBrandsLoading = true;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: "/brands/",
          params: {
            ids: ids.join(","),
            limit: ids.length,
            offset: 0,
          },
        });
        if (data.results?.length) {
          this.selectedBrands = data.results.map(brandMapper);
        } else {
          this.$toast(
            `Бренды привязанные к этой воронке не найдены. Они будут удалены после сохранения`,
            {
              type: "error",
            }
          );
        }
      } catch (error) {
        this.$toast(
          `Не удалось получить список брендов этой воронки. ${error.message}`,
          {
            type: "error",
          }
        );
      }
      this.initialBrandsLoading = false;
    },
    async getRoutes() {
      this.routesLoading = true;
      try {
        await this.$store.dispatch("talent/getAllRoutes");
      } catch (error) {
        this.$toast(
          `Не удалось получить список направлений. ${error.message}`,
          {
            type: "error",
          }
        );
      } finally {
        this.routesLoading = false;
      }
    },
    handleRemoveRegion(region) {
      const idx = this.selectedRegions.indexOf(region);
      if (idx >= 0) {
        this.selectedRegions.splice(idx, 1);
      }
    },
    handleRemoveCity(city) {
      const idx = this.selectedCities.indexOf(city);
      if (idx >= 0) {
        this.selectedCities.splice(idx, 1);
      }
    },
    handleRemoveBrand(brand) {
      const idx = this.selectedBrands.findIndex((n) => n.id === brand.id);
      if (idx >= 0) {
        this.selectedBrands.splice(idx, 1);
      }
    },
    handleRemoveRoute(routeId) {
      const idx = this.selectedRoutes.indexOf(routeId);
      if (idx >= 0) {
        this.selectedRoutes.splice(idx, 1);
      }
    },

    getFormPayload() {
      const { ids, selectedTags, selectedQuery, boardName } = this;
      const payload = {
        name: boardName,
        ...(selectedQuery && { talent_query_id: selectedQuery }),
        tags: selectedTags.map((n) => n.id),
        talent_ids: ids,
        talent_regions: [...this.selectedRegions],
        talent_cities: [...this.selectedCities],
        talent_brands: this.selectedBrands.map((n) => n.id),
        talent_routes: [...this.selectedRoutes],
      };
      return payload;
    },
  },
};
</script>

<style></style>
