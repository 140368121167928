<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5 rm-dialog-title"
        >Редактировать колонку</v-card-title
      >
      <v-card-text class="black--text">
        <validation-observer
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit"
        >
          <validation-provider
            v-slot="{ errors }"
            :rules="{ required: true, min: 3, max: 100 }"
          >
            <v-text-field
              v-model="name"
              label="Название колонки"
              autofocus
              clearable
              :error-messages="errors"
            ></v-text-field>
          </validation-provider>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="handleSubmit">{{
          isEdit ? "Сохранить" : "Создать колонку"
        }}</v-btn>
        <v-btn color="primary" outlined @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ColumnEditDialog",
  props: {
    value: Boolean,
    column: {
      type: Object,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    isEdit() {
      return !!this.column?.id;
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val && this.column?.id) {
          this.name = this.column.name;
        }
      },
    },
  },
  methods: {
    reset() {
      this.name = "";
      this.$refs.form.reset();
    },
    handleClose() {
      this.reset();
      this.$emit("input", false);
    },

    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.$emit("onSubmit", this.column?.id, {
        name: this.name,
      });
      this.handleClose();
    },
  },
};
</script>

<style></style>
