<template>
  <v-dialog
    :value="value"
    max-width="600px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="text-h5 rm-dialog-title"
        >Поиск пользователей</v-card-title
      >
      <v-card-text>
        <div class="mb2 text-body-1">
          <p>
            Вы&nbsp;можете использовать эти параметры, чтобы найти нужных вам
            пользователей в&nbsp;этой воронке. После ухода со&nbsp;страницы
            параметры поиска будут сброшены. Чтобы отредактировать выборку
            пользователей в&nbsp;воронке воспользуйтесь
            <router-link :to="{ name: 'boards_update', params: $route.params }"
              >настройками источника</router-link
            >
            данных.
          </p>
        </div>
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <v-text-field
                v-model.trim="filters.search"
                label="Введите почту или ФИО"
                clearable
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <v-select
                v-model="filters.sex"
                :items="sexOptions"
                label="Пол"
                item-text="title"
                placeholder="Выберите пол"
                item-value="value"
                clearable
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <address-input
                v-model.trim="filters.city"
                :initial-value="filters.city"
                filter="city"
                is-crm-geo
                label="Город"
              />
            </v-col>
            <v-col cols="12" xs="12" sm="6">
              <address-input
                v-model.trim="filters.region_with_type"
                :initial-value="filters.region_with_type"
                filter="region_with_type"
                is-crm-geo
                label="Регион"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" xs="12">
              <v-range-slider
                v-model="range"
                :max="ageMax"
                :min="ageMin"
                label="Возраст"
                :thumb-size="18"
                thumb-label="always"
                class="mt-2"
                color="primary"
              >
                <template #append>
                  <v-icon class="age-reset" @click="resetAges">
                    mdi-close
                  </v-icon>
                </template>
              </v-range-slider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="d-sm-flex justify-space-between d-block">
        <div class="d-block">
          <v-btn
            class="button mt-0"
            color="primary"
            @click="applyFilters(false)"
          >
            Применить фильтры
          </v-btn>
          <v-btn
            color="primary "
            class="button ml-sm-2"
            outlined
            :disabled="!isFiltersApplied && isCurrentFiltersEmpty"
            @click="resetFilters"
          >
            Сбросить фильтры
          </v-btn>
        </div>
        <v-btn class="button" color="secondary" outlined @click="handleClose"
          >Отмена</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddressInput from "@/components/contact/AddressInput";
const AGE_MIN = 1;
const AGE_MAX = 100;
const initialFilters = () => {
  return {
    age_max: AGE_MAX,
    age_min: AGE_MIN,
    city: "",
    region_with_type: "",
    search: "",
    sex: null,
  };
};
export default {
  name: "UserFilterDialog",
  components: { AddressInput },
  props: {
    value: Boolean,
    appliedFilters: Object,
    isFiltersApplied: Boolean,
  },
  data() {
    return {
      filters: initialFilters(),
      sexOptions: [
        { title: "Мужской", value: "m" },
        { title: "Женский", value: "w" },
      ],
      ageMin: AGE_MIN,
      ageMax: AGE_MAX,
    };
  },
  computed: {
    range: {
      get() {
        return [this.filters.age_min, this.filters.age_max];
      },
      set(val) {
        this.filters.age_min = val[0];
        this.filters.age_max = val[1];
      },
    },
    isCurrentFiltersEmpty() {
      const { filters } = this;
      return !Object.keys(filters).some((key) => {
        if (key === "age_max") {
          return filters[key] !== AGE_MAX;
        } else if (key === "age_min") {
          return filters[key] !== AGE_MIN;
        } else {
          return filters[key];
        }
      });
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.populateFilters();
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
    resetAges() {
      this.filters.age_max = this.ageMax;
      this.filters.age_min = this.ageMin;
    },
    populateFilters() {
      const { filters } = this;
      Object.keys(filters).forEach((key) => {
        if (key === "age_max" && !this.appliedFilters[key]) {
          this.filters[key] = this.ageMax;
        } else if (key === "age_min" && !this.appliedFilters[key]) {
          this.filters[key] = this.ageMin;
        } else {
          this.filters[key] = this.appliedFilters[key];
        }
      });
    },
    applyFilters(isReset) {
      const { ageMin, ageMax } = this;
      const filters = { ...this.filters };
      filters.age_max = filters.age_max === ageMax ? null : filters.age_max;
      filters.age_min = filters.age_min === ageMin ? null : filters.age_min;
      this.$emit("applyFilters", filters);
      if (!isReset) {
        this.handleClose();
      }
    },
    resetFilters() {
      this.filters = initialFilters();
      this.applyFilters(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.age-reset {
  &:hover {
    color: var(--v-primary-base);
  }
}
.button {
  @media screen and (max-width: 600px) {
    width: 100%;
    display: block;
    margin-top: 8px;
  }
}
</style>
